import React, { useEffect } from 'react';
// import DuolingoPhoto from '../../assets/img/my-projects/duolingo.png';
import AppleStoreWebsitePhoto from '../../assets/img/my-projects/apple-store-website.jpg';
import AsiaPrimeTravelWebsitePhoto from '../../assets/img/my-projects/asia-prime-travel-photo.png';
import JardinStudioPhoto from '../../assets/img/my-projects/jardin-studio.png';

function MyProjects() {
  const projectsList = [
    // {id: 1, title: 'Duolingo Clone', language: 'VueJS', link: 'https://duolingo-vuejs-frontend.vercel.app/', photo: DuolingoPhoto, feature: 'Sign-in, sign-up, learning lesson,...'},
    {id: 2, title: 'Apple Store Clone', language: 'PHP', link: 'https://apple-store-management-site.vercel.app/', photo: AppleStoreWebsitePhoto, feature: "Sign-in, sign-up, update cart, update user's information..."},
    {id: 3, title: 'Asia Prime Travel', language: 'NuxtJS', link: 'https://travelprime.asia/', photo: AsiaPrimeTravelWebsitePhoto, feature: 'Booking services like hotel, flight ticket, tour, handle booking email,... '},
    {id: 4, title: 'Jardin Studio', language: 'NextJS', link: 'https://jardinstudio.vercel.app/', photo: JardinStudioPhoto, feature: 'Booking services like yearbook, photobook, wedding, photoplanner, handle booking email,... '},
  ]

  const handleAnimationOnScroll = () => {
    const itemList = document.querySelectorAll('#container-header, #projects-list .project-item');
    const windowHeight = window.innerHeight;
    const threshold = 200;

    itemList.forEach(item => {
      const itemRect = item.getBoundingClientRect();

      if (itemRect.top + threshold < windowHeight) {
        item.classList.add('slide-in');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleAnimationOnScroll);
    handleAnimationOnScroll();

    return () => {
      window.removeEventListener('scroll', handleAnimationOnScroll);
    };
  }, []);

  return (
    <div id="my-projects">
      <section id="my-projects-container">

        <div id="container-header">
          <h1>Recent Projects</h1>
          <p>Projects below are some of my products, for both development with learning purpose and production with commercial purpose.</p>
        </div>

        <div id="projects-list">
          
          {projectsList.map((project) => (
          <div key={project.id} className="project-item">
            <a href={project.link} target='_blank' rel="noreferrer">

              <div className="project-image">
                <img src={project.photo} alt="my-avatar" />
              </div>

              <div className="project-content">
                <div className="project-language">
                  <p>{project.language}</p>
                </div>
                <div className="project-title">
                  <h3>{project.title}</h3>
                </div>
                <div className='project-feature'>
                  <span>Feature: {project.feature}</span>
                </div>
              </div>

            </a>

          </div>
          ))}

        </div>
      </section>
    </div>
  );
}

export default MyProjects;