// import './App.css';
import React from 'react';
import './assets/styles/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

// App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
// import About from './pages/About';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="*" element={<NotFound/>} />
        {/* <Route path="/about" element={<About/>} /> */}
      </Routes>
    </Router>
  );
}

export default App;
