export const pageFunction = {

  handleScroll(itemToScroll) {
    const element = document.getElementById(itemToScroll);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  },

  // convertedLink(link) {
  //   return `${link.replace(/[(:].*$/, "").replace(/[^\w\s]/g, "").replace(/\s\s+/g, ' ').trim().replace(/\s/g, "-").toLowerCase()}` 
  // }

};
