import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Introduction from './HomeBody/Introduction';
import MySkills from './HomeBody/MySkills';
import MyProjects from './HomeBody/MyProjects';
import ContactMe from './HomeBody/ContactMe';

function useIntersectionObserver(ref, callback) {
  const isMobile = window.innerWidth <= 768;
  const isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;

  let thresholdValue;
  if (isMobile) {
    thresholdValue = 0.2;
  } else if (isTablet) {
    thresholdValue = 0.4;
  } else {
    thresholdValue = 0.6;
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            callback(entry.target.id);
          }
        });
      },
      {
        threshold: thresholdValue,
      }
    );

    observer.observe(ref.current);

      return () => observer.disconnect();
  }, [ref, callback, thresholdValue]);
}

function HomeBody({sendInViewSection}, ref) {
  const aboutRef = useRef(null);
  const skillsRef = useRef(null);
  const projectsRef = useRef(null);
  const contactRef = useRef(null);
  const [inView, setInView] = useState(null)

  useIntersectionObserver(aboutRef, sectionId => {
    sectionInViewHandle(sectionId)
  });

  useIntersectionObserver(skillsRef, sectionId => {
    sectionInViewHandle(sectionId)
  });

  useIntersectionObserver(projectsRef, sectionId => {
    sectionInViewHandle(sectionId)
  });

  useIntersectionObserver(contactRef, sectionId => {
    sectionInViewHandle(sectionId)
  });

  const sectionInViewHandle = (sectionId) => {
    setInView(sectionId);
  }
  
  useEffect(() => {
    sendInViewSection(inView);
  }, [sendInViewSection, inView])

  return (
    <>
      <div id='home-body'>
        <div ref={aboutRef} id='about'><Introduction /></div>
        <div ref={skillsRef} id='skills'><MySkills /></div>
        <div ref={projectsRef} id='projects'><MyProjects /></div>
        <div ref={contactRef} id='contact'><ContactMe /></div>
      </div>
    </>
  );
}

export default forwardRef(HomeBody);
