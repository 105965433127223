import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

function ScrollTop() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollDetect = () => {
    if (window.pageYOffset > 800) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollDetect);

    return () => {
      window.removeEventListener('scroll', scrollDetect);
    };
  }, []);

  return (
    <>
      {isVisible ? (    
        <div id='scroll-top'>
          <div className='scroll-top-container' onClick={scrollTop}>
            <p>SCROLL TO TOP</p>
            <FontAwesomeIcon icon={faArrowDown} />
          </div>
        </div> ) : ( '' )
      }

    </>
  );
}

export default ScrollTop;