import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import { apiService } from '../api/apiService';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';


function Footer() {
  const inforItems = [
    {id: 1, icon: faPhone, link: 'tel:+84 (0) 243 678 77 88', isTarget: false, content: '+84 965 921 631'},
    {id: 2, icon: faEnvelope, link: 'mailto:kieutrongloc1298@gmail.com', isTarget: false, content: 'kieutrongloc1298@gmail.com'},
    {id: 3, icon: faLocationDot, link: 'https://goo.gl/maps/khXSnD5QwY4esQLs7', isTarget: true, content: '225 Quan Hoa St., Quan Hoa, Cau Giay, Hanoi, Vietnam.'},
  ];

  const quickConnect = [
    {id: 1, link: 'https://github.com/Kieutrongloc', isTarget: true, icon: faGithub},
    {id: 2, link: 'https://www.linkedin.com/in/loc-kieu-trong-12aa4b216', isTarget: true, icon: faLinkedin},
    {id: 3, link: 'https://www.facebook.com/kieutrongloc', isTarget: true, icon: faFacebook},
  ];

  const [weatherData, setWeatherData] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [weatherMessage, setWeatherMessage] = useState('Loading weather data...');

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        // Use IP address API (ipinfo.io) to get user's approximate location
        const response = await axios.get('https://ipinfo.io/json?token=54ace0c96badf6');
        const locationData = await apiService.getLocation(response.data.loc);
        
        if (locationData) {
          if (locationData.data.EnglishName == null) {
            setWeatherMessage('Failed to load location. Please try again!')
          } else {
            setLocationData(locationData.data);
            const weatherData = await getWeatherData(locationData.data.Key);
            setWeatherData(weatherData);
          }
        }
      } catch (error) {
        setWeatherMessage('Failed to load location. Please try again!')
        console.error('Error fetching weather data:', error);
      }
    };

    fetchWeatherData();
  }, []);

  const getWeatherData = async (locationKey) => {
    try {
      const response = await apiService.getWeather(locationKey)
      if(response.data.includes('failed')) {
        setWeatherMessage('Failed to load weather. Please try again!')
      } else {
        return response.data[0];
      }
    } catch (error) {
      setWeatherMessage('Failed to load weather. Please try again!')
      console.error('Error fetching weather data:', error);
      return null;
    }
  };

  const handleAnimationOnScroll = () => {
    const itemList = document.querySelectorAll('#page-footer-container');
    const windowHeight = window.innerHeight;
    const threshold = 300;

    itemList.forEach(item => {
      const itemRect = item.getBoundingClientRect();

      if (itemRect.top + threshold < windowHeight) {
        item.classList.add('slide-in');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleAnimationOnScroll);
    handleAnimationOnScroll();

    return () => {
      window.removeEventListener('scroll', handleAnimationOnScroll);
    };
  }, []);

  return (
    <div id="page-footer">
      <section id="page-footer-container">

        <div id="footer-main-content">
          <div id='content'>
            <h1>Trong Loc</h1>
            <h1>Web Developer</h1>
          </div>
          <div id="information">
            {inforItems.map((item) => (
              <div key={item.id} className='infor-item'>
                <FontAwesomeIcon icon={item.icon} />
                <a key={item.id} href={item.link} target={item.isTarget ? '_blank' : null} rel="noreferrer">{item.content}</a>
              </div>
            ))}
          </div>
        </div>

        <div id="footer-sub-content">
          <div id='message-infor'>
            <OverlayTrigger placement={'top'}
              overlay={
                <Tooltip>
                  Approximate location detected by IP address.
                </Tooltip>}>
                {weatherData 
                ?
                <div>
                  <img src={require(`../assets/img/weather-icons/${weatherData.WeatherIcon}-s.png`)} alt="weather-icon" />
                  <p>{weatherData.Temperature.Metric.Value}°C ({weatherData.Temperature.Imperial.Value}°F), {weatherData.WeatherText.toLowerCase()} at around {locationData.AdministrativeArea.EnglishName}.</p>
                </div>
                :
                  <p>{weatherMessage}</p>
                }
            </OverlayTrigger>
          </div>
          <div id='quick-connect'>
            {quickConnect.map((item) => (
              <a key={item.id} href={item.link} target={item.isTarget ? '_blank' : null} rel="noreferrer">
                <div>
                  <FontAwesomeIcon icon={item.icon} />
                </div>
              </a>
            ))}
          </div>
        </div>

      </section>
    </div>
  );
}

export default Footer;