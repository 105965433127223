import React, { useState, useEffect } from 'react';

function ScrollProgress() {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const updateScrollPercentage = () => {
    const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = window.scrollY;
    const percentage = (scrolled / scrollHeight) * 100;
    setScrollPercentage(percentage);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScrollPercentage);

    return () => {
      window.removeEventListener('scroll', updateScrollPercentage);
    };
  }, []);

  const progressStyle = {
    width: `${scrollPercentage}%`,
  };

  return (
    <div className="scroll-progress-container">
      <div className="scroll-progress" style={progressStyle}></div>
    </div>
  );
}

export default ScrollProgress;
