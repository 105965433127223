import React, {  useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume, faEnvelope, faX } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import CallIcon from '../assets/img/call-icon.png';
import EmailIcon from '../assets/img/email-icon.png';
import MessengerIcon from '../assets/img/messenger-icon.png';
import LinkedInIcon from '../assets/img/linkedin-icon.png';


function ContactPopup() {
  const btnExpansionRef = useRef();
  const carouselRef = useRef();
  const closeBtnRef = useRef();
  const contactContainerRef = useRef();

  const contactCarouselItems = [
    {id: 1, title: 'Contact', icon: faPhoneVolume, interval: 3000, class: 'phone-call-btn'},
    {id: 2, icon: faEnvelope, interval: 500},
    {id: 3, icon: faFacebookMessenger, interval: 500},
    {id: 4, icon: faLinkedin, interval: 500},
  ];

  const expansionItems = [
    {id: 1, title: 'Phone', img: CallIcon, href: 'tel:+84 (0) 243 678 77 88', isTarget: false, alt: 'phone call'},
    {id: 2, title: 'Email', img: EmailIcon, href: 'mailto:kieutrongloc1298@gmail.com', isTarget: true, alt: 'email'},
    {id: 3, title: 'Messenger', img: MessengerIcon, href: 'https://www.messenger.com/t/100004668341209', isTarget: true, alt: 'messenger'},
    {id: 4, title: 'LinkedIn', img: LinkedInIcon, href: 'https://www.linkedin.com/in/lucas-bao-12aa4b216/', isTarget: true, alt: 'linkedin'},
  ];

  const toggleExpansion = () => {
    if (btnExpansionRef.current.style.display === "block") {
        document.removeEventListener('click', clickOutsideHandle)
        btnExpansionRef.current.classList.add("hide");
        setTimeout(() => {
            btnExpansionRef.current.style.display = "none";
        }, 300);
        closeBtnRef.current.style.display = "none"
        carouselRef.current.element.style.display = "block";
    } else {
        document.addEventListener('click', clickOutsideHandle)
        btnExpansionRef.current.classList.remove("hide");
        btnExpansionRef.current.style.display = "block";
        carouselRef.current.element.style.display = "none";
        closeBtnRef.current.style.display = "block"
    }
  }

  const clickOutsideHandle = (event) => {
    if (!contactContainerRef.current.contains(event.target) && btnExpansionRef.current.style.display === "block") {
        toggleExpansion();
    }
  }

  return (
    <>
        <div id="contact-btn-container" ref={contactContainerRef}>
            <div className="contact-btn-wrapper">
                <div className="btn-wrapper" onClick={toggleExpansion}>

                    <Carousel id='carouselExampleControls' ref={carouselRef}>

                        {contactCarouselItems.map((item) => (
                            <Carousel.Item interval={item.interval} key={item.id}>
                                <div className="wrapper-item">
                                    <div className={`item-container d-block w-100 h-100 ${item.class ? item.class : null}`}>
                                        <FontAwesomeIcon icon={item.icon} />
                                        <p>{item.title ? item.title : ''}</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        ))}

                    </Carousel>

                    <div id="close-btn" ref={closeBtnRef}>
                        <FontAwesomeIcon icon={faX} />
                    </div>

                </div>
                <div className="btn-expansion" ref={btnExpansionRef}>
                    <ul className="expansion-list">
                        {expansionItems.map((item) => (
                            <li className="expansion-item" key={item.id}>
                                <a href={item.href} rel="noreferrer" target={item.isTarget ? '_blank' : null} className="item-wrapper">
                                    <img src={item.img} alt={item.alt} />
                                    <p>{item.title}</p>
                                </a>
                            </li>
                        ))}
                        <div className="triangle"></div>
                    </ul>
                </div>
            </div>
        </div>

    </>
  );
}

export default ContactPopup;
